<template>
  <k-object-selector
      :value="value"
      :label="label"
      @input="changed"
      :itemFunc="itemFunc"
      :suggestionsFunc="suggestionsFunc"
      objectName="virtual_account"
      objectNamePlural="virtual_accounts"
  >
    <template slot="item" slot-scope="{ item }">
      <v-list-item-content v-if="item.meta._name">
        <v-list-item-title>{{ item.meta._name }}</v-list-item-title>
        <v-list-item-subtitle>{{ item.id }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-content v-else>
        <v-list-item-title>{{ item.id }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-list-item-action-text>
          <k-value v-model="item.balance"/>
        </v-list-item-action-text>
      </v-list-item-action>
    </template>
  </k-object-selector>
</template>

<script>
import api from '@/services/api';

export default {
  props: ['value', 'label'],
  methods: {
    changed(value) {
      this.$emit('input', value);
    },
    itemFunc(id) {
      return api.kernel.get(`/virtual_accounts/${id}`);
    },
    suggestionsFunc(search) {
      if (!search) {
        return api.kernel.get('/virtual_accounts?limit=12').then((data) => data.data);
      }
      if (search.match('^vacc_[123456789abcdefghjklmnpqrstuvxyz]{20}$')) {
        return api.kernel.get(`/virtual_accounts/${search}`).then((data) => [data]);
      }
      return new Promise((resolve) => resolve([]));
    },
  },

};
</script>
